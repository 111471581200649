/* eslint-disable no-shadow */
import React, { useState } from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

// Components
import ModalButton from '../../components/ModalButton/ModalButton';
import RefinementList from '../../components/Discovery/RefinementList/RefinementList';
import CustomBreadcrumb from '../../components/CustomBreadcrumb/CustomBreadcrumb';
import SEO from '../../components/SEO/SEO';
import SearchBox from '../../components/Discovery/SearchBox/SearchBox';
import Card from '../../components/AppMarketplace/Card/Card';

// Style
import './app-marketplace.scss';
import MicroLayout from '../../layouts/Micro';

// Utils
import titleCase from '../../utils/titleCase';

const AppMarketplace = ({ data, location }) => {
  // Apps Data
  const appsDataDefault = data.allAppMarketplaceJson.edges;
  const [appsData, setAppsData] = useState(appsDataDefault);

  // Categories Component State and Data
  const [isClicked, setIsClicked] = useState();

  const categories = [...new Set(appsDataDefault.map((data) => JSON.stringify(data.node.app_tag)))]
    .map((string) => JSON.parse(string))
    .sort((a, b) => `${a}`.localeCompare(b))
    .map((data) => ({
      title: titleCase(data.toString().replace(/-+/g, ' ')),
      slug: data.toString(),
    }));

  const [activeItem, setActiveItem] = useState();
  const onSelect = (prevItem, selectedItem) => {
    const prevSelectedItem = prevItem[1];

    setActiveItem(selectedItem);

    const filtered = appsDataDefault.filter(
      (data) =>
        selectedItem &&
        data.node.app_tag &&
        data.node.app_tag.length &&
        data.node.app_tag.toString().toLowerCase().includes(selectedItem.toLowerCase())
    );

    if (!isClicked) {
      setIsClicked(true);
      setActiveItem(selectedItem);
      setAppsData(filtered);
    } else if (prevSelectedItem !== selectedItem) {
      setIsClicked(true);
      setActiveItem(selectedItem);
      setAppsData(filtered);
    } else {
      setIsClicked(false);
      setActiveItem('');
      setAppsData(appsDataDefault);
    }
  };

  // Search Component State and Functions
  const [query, setQuery] = useState();
  const updateInput = async (input) => {
    const filtered = appsDataDefault.filter((data) =>
      data.node.app_title.toLowerCase().includes(input.toLowerCase())
    );
    setQuery(input);
    setAppsData(filtered);
  };

  return (
    <MicroLayout>
      <SEO title="App Marketplace" />
      <section className="app-market p-1" style={{ backgroundColor: '#F0F9FB' }}>
        <div className="container">
          <div className="row flex-middle">
            <div className="col-lg-3">
              <CustomBreadcrumb location={location} className="m-0" />
              <h3 className="app-market-hero-title text-bold">
                The Leading App Store for Remote Teams
              </h3>
              <p className="app-market-hero-desc my-1">
                Find all the remote tools and services you need to run a successful remote team.
              </p>
            </div>
            <div className="col-lg-3 pl-4-4">
              <img
                className="img-responsive"
                src="https://res.cloudinary.com/hebu10lmu/image/upload/v1604425157/www/forestry/AppMarketplaceImage_V2_iul5pj.svg"
                alt="App Marketplace"
              />
            </div>
          </div>
        </div>
      </section>
      <section className="container p-1 mt-2">
        <div className="row">
          {/* Left */}
          <div
            className="col-lg-1 col-md-2 col-sm-6 col-xs-6 p-0"
            style={{ height: '100%', position: 'sticky' }}
          >
            <RefinementList
              style={{ height: 'auto !important' }}
              menuTitle="Categories"
              data={categories}
              onSelect={onSelect}
              activeItem={activeItem}
              isHorizontal={false}
              isVertical
            />
            <ModalButton title="Become a partner">
              <div
                className="calendly-inline-widget"
                data-url="https://calendly.com/remoteteam/demo"
                style={{ width: '90%', height: '80%' }}
              />
              <script
                type="text/javascript"
                src="https://assets.calendly.com/assets/external/widget.js"
              />
            </ModalButton>
          </div>
          {/* Left */}
          {/* Right Start */}
          <div className="col-lg-5 col-md-4 col-sm-6 col-xs-6 p-0">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 p-0">
                <SearchBox
                  query={query}
                  placeholder="Search for apps (category, features, app name)"
                  onChange={updateInput}
                />
              </div>
            </div>
            <div className="row">
              {appsData.map((data) => (
                <div className="col-lg-2 col-md-3 col-sm-3 col-xs-6">
                  <Card data={data.node} />
                </div>
              ))}
            </div>
          </div>
          {/* Right End */}
        </div>
      </section>
    </MicroLayout>
  );
};

AppMarketplace.propTypes = {
  data: PropTypes.objectOf.isRequired,
  location: PropTypes.objectOf.isRequired,
};

export default AppMarketplace;

export const appMarketPageQuery = graphql`
  {
    allAppMarketplaceJson(
      sort: { order: ASC, fields: app_title }
      filter: { app_is_www: { eq: true }, app_is_ready: { eq: true } }
    ) {
      edges {
        node {
          app_id
          app_title
          app_desc
          app_overview_content
          app_ready
          app_slides {
            full_thumbnail
          }
          app_tag
          app_tag_color
          is_integrated
          app_discount
          app_discount_curreny
          app_features
          app_integration_content
          app_logo
          app_annually_plans {
            currency
            name
            price
            type
          }
          app_monthly_plans {
            currency
            name
            price
            type
          }
          app_plans {
            currency
            name
            price
            type
          }
        }
      }
    }
  }
`;
