import React from 'react';
import PropTypes from 'prop-types';
// Style
import './modal-style.scss';

const ModalButton = ({ title, children }) => (
  <details className="remoteteam_modal">
    <summary className="remoteteam_modal-text">{title}</summary>
    <div className="remoteteam_modal-content">{children}</div>
  </details>
);

ModalButton.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.arrayOf.isRequired,
};

export default ModalButton;
