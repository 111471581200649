/* eslint-disable react/prop-types */
import React from 'react';
import Link from '../../Link/Link';

// Style
import './card.scss';

// Utils
import hexToRgba from '../../../utils/hexToRgba';
import shadeColor from '../../../utils/shadeColor';
// import getFirstPricePlan from '../../../utils/getFirstPricePlan';
// import convertCurrency from '../../../utils/convertCurrency';

const Card = ({ data }) => (
  <div className="custom-app-card">
    <Link
      href={`/app-marketplace/${data.app_title
        .toLowerCase()
        .replace('.', '-')
        .replace(/ +/g, '-')}`}
      className="custom-app-card-link"
    />

    {/* {data.app_discount.toString() <= 0 ||
        (data.app_ready && (
          <div className="custom-app-card-overlay">
            <div className="custom-app-card-overlay_badge custom-app-card-overlay_badge-green">
              <span className="text-bold">
                {data.app_discount.toString()}% Discount
              </span>
            </div>
          </div>
        ))} */}

    <div className="custom-app-card-image mt-1 mb-1 flex flex-start flex-middle">
      <img
        className="img-responsive p-1"
        loading="lazy"
        width="96"
        src={data.app_logo}
        alt={data.app_title}
      />
    </div>
    <div className="custom-app-card-content">
      <div className="top-content flex flex-center flex-between">
        <h6 className="mr-auto mb-0 text-left">{data.app_title}</h6>
        <Link
          href={`/app-marketplace/${data.app_title
            .toLowerCase()
            .replace('.', '-')
            .replace(/ +/g, '-')}`}
          className="details-text"
        >
          Details
        </Link>
      </div>
      <div className="middle-content">
        <p className="mt-1 mb-1 text-muted">{data.app_desc}</p>
      </div>
      <div className="bottom-content">
        {/* <Link
          href={`/app-marketplace/tags/${data.app_tag
            .toString()
            .toLowerCase()
            .replace(/ +/g, '-')}`}
          className="custom-app-badge mb-1"
          style={{
            backgroundColor: hexToRgba(data.app_tag_color.toString(), 0.3),
          }}
        > */}
        <span className="custom-app-badge mb-1">
          <span
            className="text-capitalize"
            style={{
              color: hexToRgba(shadeColor(data.app_tag_color.toString(), -40), 1),
            }}
          >
            {data.app_tag[0].replace(/-+/g, ' ')}
          </span>
        </span>
        {/* </Link> */}

        {/* <hr className="my-1" style={{ width: "100%" }} />
          <div className="pricing_area">
            <h6 style={{ marginBottom: 8 }}>Starting at</h6>
            <div className={`flex ${data.app_discount && "flex-between"}`}>
              <h2 style={{ color: "#2d46c1" }}>
                {getFirstPricePlan(data.app_plans, data.app_discount)}
              </h2>

              {Number(data.app_plans[0].price) !== 0 &&
                data.app_discount.length > 0 && (
                  <h3
                    className="text-muted"
                    style={{ textDecoration: "line-through" }}
                  >
                    {convertCurrency(
                      data.app_plans[0].price,
                      data.app_plans[0].currency
                    )}
                  </h3>
                )}
            </div>
            <div className="flex flex-between">
              <p className="text-muted mb-0 text-bold" style={{ fontSize: 14 }}>
                Get started with {data.app_plans[0].name}
              </p>
              {Number(data.app_plans[0].price) !== 0 &&
                data.app_discount.length > 0 && (
                  <p
                    className="text-muted mb-0 text-bold"
                    style={{ fontSize: 14 }}
                  >
                    Original Price
                  </p>
                )}
            </div>
          </div> */}
      </div>
    </div>
  </div>
);

export default Card;
